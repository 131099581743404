import React, { useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import Snackbar from "@mui/material/Snackbar";
import axios from 'axios'

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. We will contact you soon.
    </p>
  );
};

function ContactForm({ props }) {
  const recaptchaRef = useRef()
  const [result, showresult] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [values, setValues] = useState({
    subject: '',
    name: '',
    email: '',
    message: '',
  });
  const [openNotif, setNotif] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    setButtonLoading(true)

    const { email, name, message, subject } = values

    if (!email || !name || !message) {
      return
    }

    try {
      const token = await recaptchaRef.current.executeAsync();
  
      await axios.post('/api/contact/', {
        name,
        subject,
        email,
        message,
        token,
      })
    } catch (err) {
      throw err
    } finally {
      setButtonLoading(false)
      setValues({
        ...values,
        name: '',
        email: '',
        message: '',
        subject: '',
      })
      showresult(true)
    }
  };

  const handleClose = () => {
    setNotif(false);
  };

  const updateField = (prop, value) => {
    const vals = { ...values }
    vals[prop] = value;

    setValues(vals)
  }

  // setTimeout(() => {
  //   showresult(false);
  //   console.log('reloading in contestForm')
  //   window.location.reload()
  // }, 6000);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key="top right"
        open={openNotif}
        autoHideDuration={4000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">Message Sent</span>}
      />
      <form action="" onSubmit={handleSubmit}>
        <div className="rn-form-group">
          <input
            type="text"
            name="fullname"
            placeholder="Your Name"
            onChange={(e) => updateField('name', e.target.value)}
            value={values.name}
            required
          />
        </div>

        <div className="rn-form-group">
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            onChange={(e) => updateField('email', e.target.value)}
            value={values.email}
            required
          />
        </div>

        <div className="rn-form-group">
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            onChange={(e) => updateField('subject', e.target.value)}
            value={values.subject}
            required
          />
        </div>

        <div className="rn-form-group">
          <textarea
            name="message"
            placeholder="Your Message"
            onChange={(e) => updateField('message', e.target.value)}
            value={values.message}
            required
          ></textarea>
        </div>

        <div className="rn-form-group">
          <ReCAPTCHA
            theme="dark"
            style={{ display: "none" }}
            ref={recaptchaRef}
            size='invisible'
            sitekey='6Ldkc9opAAAAAFYveY-Dh3YKXOMFgL9dS9wsvucN'
          />
          <button
            disabled={buttonLoading}
            className="rn-button-style--2 btn-solid"
            type="submit"
            value="submit"
            name="submit"
            id="mc-embedded-subscribe"
          >
            Submit Now
          </button>
        </div>

        <div className="rn-form-group">{result ? <Result /> : null}</div>
      </form>
    </>
  );
}
export default ContactForm;
